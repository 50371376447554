import { useState } from "react";
import Nav from "./Nav";

function Enter() {

  const [sendContent, setSendContent] = useState('')
  const handleSubmit = async (e) => {
    e.preventDefault();



    var data = new FormData();
    data.append('sendVal', JSON.stringify(sendContent));
    data.append('act', 'enter_data');

    const mark = await fetch('https://doug.art-technology.org/db/index.php',
      {
        method: 'POST',
        mode: 'no-cors',
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'application/json'
        },
        body: data
      }).then(response => {
        console.log('response', response);
        setSendContent('');
      });



  }

  return (
    <div className="container">
      <Nav />
      <div className="Enter">
        <form onSubmit={handleSubmit}>
          <input type="submit" value="Submit" className="submit_button" />

          <textarea placeholder="capture" className="submit_area" value={sendContent} onChange={e => setSendContent(e.target.value)} />


        </form>
      </div>
    </div>
  );
}

export default Enter;
