import { useEffect, useState } from "react";
import Nav from "./Nav";

function Print() {
  const [bodyValues, setBodyValues] = useState({})
  const [calendarData, setCalendarData] = useState([])
  const [boxValues, setBoxValues] = useState({})

  const pullApi = async () => {
    try {
      const response = await fetch(
        'https://doug.art-technology.org/db/index.php?act=all',
      )

      let dataReturn = await response.json()

      setBodyValues(dataReturn.body);

      let boxData = {}
      for (let data of dataReturn.body.cues) {
        if (undefined === boxData[data.label]) { boxData[data.label] = [] }
        boxData[data.label].push(data)
      }
      setBoxValues(boxData)

      let dateRow = [];
      for (let indx of [0, 1, 2, 3, 4, 5, 6]) {
        let bot = dataReturn.body.marks[indx].epoch;
        let top = dataReturn.body.marks[indx + 1].epoch;
        let scheds = dataReturn.body.cals.filter(item => {
          return (item.start <= top && item.start >= bot)
        })
        dataReturn.body.marks[indx].events = scheds
      }

      dataReturn.body.marks = dataReturn.body.marks.filter((item, x) => {
        return x < 7
      })

      setCalendarData(dataReturn.body.marks)
    } catch (error) {
      console.log('error', error)
    }
  }

  useEffect(() => {
    pullApi();
  }, []);


  const titleCase = (string) => {
    return string[0].toUpperCase() + string.substring(1)
  }

  return (
    <div className="Print">

      <span className="hide_from_print"><Nav /></span>
      <div className="date_row">
        {calendarData.map((day) => {

          return (
            <div className="date" key={day.date}>
              <div className="day">{day.date}</div>
              <div className="date_content">
                <ul>
                  {day.events.map(event => {
                    return (
                      <li key={event.id}>{event.title}</li>
                    )
                  })}
                </ul>
              </div>
            </div>
          )
        })}

      </div>
      <div className="box_row">
        {Object.keys(boxValues).map((box) => {
          return (
            <div className="box" key={box}>
              <h3 className="box_title">{box}</h3>
              <ul className="box_events">
                {boxValues[box].map(event => {
                  return (<li key={event.id}>{titleCase(event.name)}</li>)
                })}
              </ul>
            </div>
          )
        })}
      </div>
    </div>
  );
}

export default Print;
