import DragList from "./DragList";
import Enter from "./Enter";
import Print from "./Print";
import Login from "./Login";
import { useCookies } from 'react-cookie';


import { Route, Routes } from "react-router-dom";

function App() {
  const [cookies, setCookie] = useCookies(['mlog']);

  if (undefined === cookies.mlog || cookies.mlog !== 'mcohen') {
    return (
      <Login />
    )
  } else {

    return (
      <Routes>
        <Route path="/" element={<DragList />} />
        <Route path="/enter" element={<Enter />} />
        <Route path="/print" element={<Print />} />


      </Routes>


    );
  }
}


export default App;
